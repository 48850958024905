import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import usuarioImage from '../img/usuario.png';
import logoImage from '../img/log.png';
import { handleLogout } from '../login/Logout';
import { useNavigate } from 'react-router-dom'
import { varAdmin } from "../servicios/api"

const Header = () => {
    const username = localStorage.getItem('username');
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate() 
    const userRole = localStorage.getItem('userRol');   
    

    const handleImageClick = () => {
        setShowMenu(!showMenu);
    };

    const handleMenuOptionClick = (option) => {
        // Implementa la lógica correspondiente para cada opción del menú
        if (option === 'usuarios') {
            // Acción cuando se selecciona "Usuarios"
            navigate('/viewusers');
        } else if (option === 'cerrarSesion') {
            
            handleLogout(navigate);
        }

        // Cierra el menú después de hacer clic en una opción
        setShowMenu(false);
    };

    return (
        <header>
            <div className="header">
                <div className="corner-image" onClick={handleImageClick} style={{ cursor: 'pointer' }}>
                    <img src={logoImage} alt="Logo" style={{ width: '100px', height: '50px' }} />
                </div>
                <h1 className="header__title">AUDITORIA TURÍSTICA LOCALES OCIO</h1>
                <div className="header__user-info">
                    <p className="header__username">{username}</p>
                    <div onClick={handleImageClick} style={{ cursor: 'pointer' }}>
                        <img src={usuarioImage} alt="Imagen de perfil" className="header__profile-image" />
                    </div>
                    {/* Menú desplegable */}
                    <Dropdown show={showMenu} align="end">
                        <Dropdown.Menu>
                        {userRole === varAdmin && (
                                  <Dropdown.Item onClick={() => handleMenuOptionClick('usuarios')}>Usuarios</Dropdown.Item>
                              )}
                            
                            <Dropdown.Item onClick={() => handleMenuOptionClick('cerrarSesion')}>Cerrar Sesión</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </header>
    );
};

export default Header;



